import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { ElLoading, ElMessage } from 'element-plus'
import store from '@/store'

let loading: any;

export const startLoading = () => {

    const options: Options = {
        lock: true,
        text: "加载中...",
        background: 'rgba( 0, 0, 0, 0.7 )'
    }

    loading = ElLoading.service( options );
}

export const stopLoading = () => {
    loading.close();
}

// 定义axois
const instance = axios.create( {
    // 解决跨域
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 20000,
} );

//请求拦截
instance.interceptors.request.use( ( config: AxiosRequestConfig ) => {
    // 加载loading
    startLoading();
    return config;
} );


// 响应拦截
instance.interceptors.response.use( ( response: AxiosResponse< any > ) => {
    //结束loading
    stopLoading();

    return response;
}, error => {
    stopLoading();
    if( error.response.status == 401 ){
        store.dispatch( 'logout' )
    }
    ElMessage( { message : error.response.data.message, type : 'error', showClose : true } )
    return Promise.reject( error );
} )



// 重写get
export const get = async function( url : string, params : object ){
    // 添加JWT Auth header
    let headers = { Authorization : 'bearer ' + localStorage.getItem( 'token' ) }

    let { data } = await instance.get( url, { params: params, headers: headers } );
    return data;
}



// 重写post
export const post = async function( url : string, params : object ) {
    // 添加JWT Auth header
    let headers = { Authorization : 'bearer ' + localStorage.getItem( 'token' ) }

    let { data } = await instance.post( url, params, { headers : headers } );
    return data;
}



// 在plugins/Requests.ts中注册get and post

interface Options {
    lock: boolean;
    text: string;
    background: string;
}
