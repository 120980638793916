import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate';
import router from '@/router'
import { User } from '@models/User.ts'



interface State{
    auth : boolean,
    user : User | null,
}


const state : State = {
    auth : false,
    user : null
}


const store = createStore( {
    state: {
        auth : state.auth,
        user : state.user
    },



    mutations: {
        SET_USER: ( state, data : User ) => {
            state.auth = true
            state.user = data
        },


        CLEAR_USER: ( state ) => {
            state.user = null
            state.auth = false
        }
    },



    actions: {
        login ( { commit }, data ) {
            localStorage.setItem( 'token', data.token )
            //let token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9jcy1hcGkubG9jYWwiLCJqdGkiOiJDdXN0b21lciBTZXJ2aWNlIGFwaSBTZXJ2ZXIiLCJpYXQiOjE2MzM4MzQ5MDQsImV4cCI6MTYzMzg2MzcwNCwiaXN0IjoicHJvamVjdCIsImRhdGEiOnsiaWQiOjEyLCJuYW1lIjoiXHU2NzJhXHU2ZmMwXHU2ZDNiIiwidXNlcm5hbWUiOiJpbmFjdGl2ZUBpbmFjdGl2ZS5jb20iLCJlbWFpbCI6ImluYWN0aXZlQGluYWN0aXZlLmNvbSIsImFjdGl2ZV9mbGFnIjpmYWxzZSwicm9sZSI6MywiY3JlYXRlZF9hdCI6IjIwMjEtMTAtMDlUMDk6MTY6MDYuMDAwMDAwWiIsInVwZGF0ZWRfYXQiOiIyMDIxLTEwLTA5VDA5OjE2OjA2LjAwMDAwMFoiLCJyb2xlX25hbWUiOiJTdXBwb3J0In19.I97JdzgyzXNi_te5mPKsAwv0DT6JwhOOIfV6Xfm9_II'

            //localStorage.setItem( 'token', token )
            commit( 'SET_USER', data.user )
            router.push( '/' )
        },


        logout ( { commit } ) {
            localStorage.clear();
            commit( 'CLEAR_USER' )
            router.push( '/login' )
        },

    },



    getters: {
        isLoggedIn :  ( state ) => {
            return state.auth
        },


        user : ( state ) => {
            return state.user
        }
    },


    modules : {
    },


    plugins : [
        createPersistedState()
    ]

} )


export default store
