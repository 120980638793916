import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import { ElMessage } from 'element-plus'
import { disconnect } from '@plugins/opentok'



// @ts-ignore
const hideFromAuth = ( to : any, from : any, next : any ) => {
    if( store.getters.isLoggedIn ){
        ElMessage( { message: 'Please contact Admin or Manager to activate your account.', type: 'warning', showClose : true } )
        next( from )
    }else{
        next()
    }
}


const routes = [
    {
        path: '/',
        alias : [ '/home', '/dashboard' ],
        name: 'Home',
        component: () => import( '@views/home/index.vue' ),
        meta : { requiresAuth : true }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import( '@views/login/index.vue' ),
        beforeEnter : hideFromAuth
    },
    {
        path: '/inactive',
        name: 'Inactive',
        component: () => import( '@views/login/inactive.vue' ),
        beforeEnter : ( to, from, next ) => { store.getters.isLoggedIn ? next() : next( { name: 'Login' } ) }
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import( '@views/register/index.vue' ),
        beforeEnter : hideFromAuth
    },
    {
        path: '/settings',
        alias : [ '/settings/index', '/settings/userInfo' ],
        name: 'UserInfo',
        component: () => import( '@views/settings/index.vue' ),
        meta : { requiresAuth : true }
    },
    {
        path: '/settings/changePassword',
        name: 'ChangePassword',
        component: () => import( '@views/settings/changePassword.vue' ),
        meta : { requiresAuth : true }
    },
    {
        path: '/settings/activation',
        name: 'Activation',
        component: () => import( '@views/settings/activation.vue' ),
        meta : { requiresAuth : true }
    },
    {
        path: '/support',
        name: 'Support',
        component: () => import( '@views/support/index.vue' ),
        meta : { requiresAuth : true }
    },
    {
        path: '/support/:id',
        name: 'Chat',
        component: () => import( '@views/support/Chat.vue' ),
        meta : { requiresAuth : true }
    },
    {
        path: '/:catchAll(.*)',
        name: '/404',
        component: () => import( '@views/404.vue' )
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

// @ts-ignore
router.beforeEach( ( to : any, from : any, next : any ) => {
    if( from.name == "Chat" ){ disconnect()
    }
    if ( to.matched.some( record => record.meta.requiresAuth ) ) {
        if ( store.getters.isLoggedIn ) {
            if( store.getters.user.active_flag ){
                next()
            }else{
                ElMessage( { message: 'Please contact Admin or Manager to activate your account.', type: 'warning', showClose : true } )
                next( { name: 'Inactive' } )
            }
        }else{
            next( { name: 'Login' } )
        }
    }else{
        next()
    }
})

export default router
