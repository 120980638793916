import { ElMessage } from 'element-plus'
import { Message } from "@models/Message.ts"
import { stopLoading } from '@utils/Axios'

const OT = require( '@opentok/client' );
const apiKey = process.env.VUE_APP_OT_API_KEY
let session;



function handleError( error ) {
    if ( error ) {
        ElMessage( { message : error.message, type : 'error', showClose : true } )
    }
}


// TODO:: only one support should be able to publish video and audio
const initializeSession = ( sessionId, token, msgs ) => {
    session = OT.initSession( apiKey, sessionId );

    // Create a publisher
    let publisher = OT.initPublisher( 'support-cam', {
        insertMode: 'replace',// replace, before, after, append
        width: '100%',
        height: '100%'
    }, handleError );

    // Connect to the session
    session.connect( token, function( error ) {
        // If the connection is successful, publish to the session
        if ( error ) {
            handleError( error );
        } else {
            session.publish( publisher, handleError );
            stopLoading()
        }
    });

    session.on( 'streamCreated', function( event ) {
        let video = document.createElement( 'div' )

        if( event.stream.videoType == 'screen' ){
            video.id = 'customer-screen'
            document.querySelector( '#customer-screen-container' ).appendChild( video );

            session.subscribe( event.stream, 'customer-screen', {
                insertMode: 'replace',
                width: '100%',
                height: '100%'
            }, handleError )
        }else{
            video.id = 'customer-cam'
            document.querySelector( '#customer-cam-container' ).appendChild( video );

            session.subscribe( event.stream, 'customer-cam', {
                insertMode: 'replace',
                width: '100%',
                height: '100%'
            }, handleError )
        }
    });

    // Display message
    session.on( 'signal:msg', function( event ) {
        let msg = JSON.parse( event.data )
        msgs.push( msg )
    });

    publisher.on("streamDestroyed", function (event) {
        event.preventDefault();
    });
}


const sendMsg = ( content, message, msg ) => {

    session.signal( {
        type: 'msg',
        data: JSON.stringify( msg )
    }, function signalCallback( error ) {
        if ( error ) {
            ElMessage( { message : error.message, type : 'error', showClose : true } )
        } else {
            message.value = ''
            ElMessage( { message : 'Mesasge sent!', type : 'success', showClose : true } )
        }
    });

}

const disconnect = () => {

    session.disconnect()

}



export { initializeSession, sendMsg, disconnect }
