import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'lib-flexible/flexible'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import requests from './plugins/Requests'



const app = createApp( App )
    app.use( ElementPlus )
    app.use( store )
    app.use( router )
    app.use( requests )
    app.mount('#app')


